import React from "react";
import { IonContent, IonPage } from "@ionic/react";
import "./Home.css";
import HomeLanding from "../layouts/HomeLanding/HomeLanding";
import HomeNews from "../layouts/HomeNews/HomeNews";
import Footer from "../layouts/Footer/Footer";
import { appConfig } from "../config";

const twitchUrl =
  "https://player.twitch.tv/?channel=line_bender&parent=" +
  appConfig.twitchParentUri;

const Home: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <HomeLanding></HomeLanding>
        <div className="twitch-embed-container">
          <iframe
            className="twitch-embed"
            src={twitchUrl}
            allowFullScreen={true}
            frameBorder="0" /* This fixes the border on Firefox */
          />
        </div>

        <HomeNews></HomeNews>
        <Footer></Footer>
      </IonContent>
    </IonPage>
  );
};

export default Home;
