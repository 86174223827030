import { createSlice } from "@reduxjs/toolkit";
export const signinVisibilitySlice = createSlice({
    name: "signin",
    initialState:{
        visible : false
    },
    reducers:{
        openSignin: (state) => {
            state.visible = true;

        },
        closeSignin: (state) => {
            state.visible = false;

        }
    }
});

export const {openSignin, closeSignin} = signinVisibilitySlice.actions;
export default signinVisibilitySlice.reducer;