// src/components/PaymentForm.tsx

import React from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { IonButton } from "@ionic/react";
import { StripePaymentElementOptions } from "@stripe/stripe-js";

interface PaymentFormProps {
  clientSecret: StripePaymentElementOptions;
}

const PaymentForm: React.FC<PaymentFormProps> = ({ clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements || !clientSecret) {
      return;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <br />
      <IonButton expand="block" type="submit" disabled={!stripe}>
        Pay Now
      </IonButton>
    </form>
  );
};

export default PaymentForm;
