import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from "@ionic/react";
import React, { useState } from "react";
import CheckoutModal from "../features/CheckoutModal/CheckoutModal";

interface StoreItemCardProps {
  type: string;
  price: number;
  description: string;
  term?: string; // Only for subscriptions
  amount?: number; // Only for coins
  img: string;
  name: string;
}

const StoreItemCard: React.FC<StoreItemCardProps> = ({
  type,
  price,
  description,
  term,
  amount,
  img,
  name,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCheckout = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <IonCard className="ion-padding">
      <img
        alt={name}
        //https://ionicframework.com/docs/img/demos/card-media.png"
        src={img}
      />
      <IonCardHeader>
        {type === "subscription" && <IonCardTitle>Term: {term}</IonCardTitle>}
        {type === "coin" && <IonCardTitle>Amount: {amount}</IonCardTitle>}
        <IonCardSubtitle>${price.toFixed(2)}</IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>{description}</IonCardContent>
      {/* Add other card content and functionality here */}
      <IonButton id="checkout-button" expand="block" onClick={handleCheckout}>
        Checkout
      </IonButton>
      <CheckoutModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        // TODO this should  be a payment intent as well
        item={{ type, price, description, term, amount, img, name }}
      />
    </IonCard>
  );
};

export default StoreItemCard;
