import React from "react";
import { IonCard, IonToolbar, IonButton, IonIcon } from "@ionic/react";
import { logoMastodon, logoYoutube, logoDiscord } from "ionicons/icons";
import "../Footer/Footer.css";
const Footer = () => {
  return (
    <IonCard className="footer">
      <IonToolbar color="light">
        <div className="ion-text-center">
          <IonButton color="dark" fill="clear" href="/">
            Home
          </IonButton>
          <IonButton color="dark" fill="clear" href="/about">
            About
          </IonButton>
          <IonButton color="dark" fill="clear" href="/contact">
            Contact
          </IonButton>
          <IonButton color="dark" fill="clear" href="/store">
            Store
          </IonButton>
          <IonButton color="dark" fill="clear" href="/play">
            Play
          </IonButton>
          <IonButton color="dark" fill="clear" href="/press">
            Press
          </IonButton>
        </div>
      </IonToolbar>
      <IonToolbar color="light">
        <div className="ion-text-center">
          <IonButton
            color="dark"
            fill="clear"
            href="https://mastodon.online/@vgx"
            target="_blank">
            <IonIcon icon={logoMastodon} />
          </IonButton>
          <IonButton
            color="dark"
            fill="clear"
            href="https://www.youtube.com/channel/UCrI4grL6iT4QZGchdPBK29A"
            target="_blank">
            <IonIcon icon={logoYoutube} />
          </IonButton>
          <IonButton
            color="dark"
            fill="clear"
            href="https://discord.gg/axSRnuyQnJ"
            target="_blank">
            <IonIcon icon={logoDiscord} />
          </IonButton>
        </div>
      </IonToolbar>
      <IonToolbar color="light">
        <div className="ion-text-center">
          <IonButton fill="clear">
            <u>Privacy Policy</u>
          </IonButton>
          <IonButton fill="clear">
            <u> Cookie Policy</u>
          </IonButton>
        </div>
      </IonToolbar>
      <IonToolbar color="light">
        <div className="ion-text-center">
          &copy; {new Date().getFullYear()} VGX. All rights reserved.
        </div>
      </IonToolbar>
    </IonCard>
  );
};

export default Footer;
