/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import {
  IonButton,
  IonIcon,
  IonButtons,
  IonImg,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonPopover,
} from "@ionic/react";
import {
  chevronForwardOutline,
  chevronBackOutline,
  chevronDown,
} from "ionicons/icons";
import { useLocation } from "react-router-dom";
import "./FloatingToolbar.css";
import logo from "../../assets/logos/vgx-light.svg";
import AccountPopover from "../../features/AccountPopover/AccountPopover";
import { useNavigationService } from "../../helpers/navigation.service";
import { openSignin } from "../../helpers/redux/SignIn";
import { useDispatch } from "react-redux";

const FloatingToolbar: React.FC = () => {
  const [expanded, setExpanded] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();

  const toggleToolbar = () => {
    setExpanded(!expanded);
  };

  const currentPage = location.pathname.replace("/", "").toUpperCase(); // Get the current page name

  const [showPopover, setShowPopover] = useState(false);
  const [event, setEvent] = useState<any>(null);
  let hoverTimer: any = null;

  const handlePopover = (e: any) => {
    clearTimeout(hoverTimer); // Clear any previous timeout
    setShowPopover(true);
    setEvent(e);
  };

  const handlePopoverDismiss = () => {
    hoverTimer = setTimeout(() => {
      setShowPopover(false);
    }, 500); // Adjust the delay time as needed
  };
  const routingChildren = useNavigationService();

  return (
    <div className={`my-toolbar ${expanded ? "expanded" : ""}`}>
      <IonButtons slot="end">
        <IonButton onClick={toggleToolbar}>
          <IonIcon
            slot="icon-only"
            color="dark"
            icon={
              expanded ? chevronForwardOutline : chevronBackOutline
            }></IonIcon>
        </IonButton>
        <IonImg src={logo} style={{ width: "70px", height: "70px" }}></IonImg>
      </IonButtons>
      {expanded && (
        <IonGrid>
          <IonRow>
            <IonCol size="8" offset="1.5">
              <div className="expandedComps">
                <IonSegment color={"primary"} value={currentPage} mode="md">
                  {routingChildren.map((child, index) => (
                    <IonSegmentButton
                      key={index}
                      value={child.value.toUpperCase()}
                      onClick={() => {
                        child.onclick();
                      }}>
                      <IonLabel className="toolbar-label">
                        {child.value}
                      </IonLabel>
                    </IonSegmentButton>
                  ))}
                </IonSegment>
              </div>
            </IonCol>
            <IonCol></IonCol>
            <IonCol offset="0" style={{ display: "flex" }}>
              <IonButton
                fill="clear"
                onMouseEnter={handlePopover}
                onMouseLeave={handlePopoverDismiss}>
                Account
                <IonIcon slot="end" icon={chevronDown}></IonIcon>
              </IonButton>

              <IonPopover
                isOpen={showPopover}
                mode="ios"
                event={event}
                onMouseEnter={handlePopover}
                onDidDismiss={() => setShowPopover(false)}>
                <AccountPopover onClose={() => handlePopover} />
              </IonPopover>
              <IonButton
                color={"secondary"}
                onClick={() => dispatch(openSignin())}>
                Play Now
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
    </div>
  );
};

export default FloatingToolbar;
