import React from "react";
import {
  IonButton,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
} from "@ionic/react";
import "./HomeLanding.css";
import PlayNowBtn from "../../assets/lotties/Play_Now_Button.json";
import LottiePlayer from "../../components/LottiePlayer";
import { IonCard } from "@ionic/react";
import { logoDiscord, logoMastodon, logoYoutube } from "ionicons/icons";
import { openSignin } from "../../helpers/redux/SignIn";
import { useDispatch } from "react-redux";

const HomeLanding: React.FC = () => {
  const dispatch = useDispatch();
  return (
    <div className="homepage-bg">
      <div id="bg-image" />
      <div>
        <br />
        <IonCard className="landing-text-card">
          <IonCardContent>
            <IonCardTitle class="landing-text-title">
              THE ISLAND IS UNDER CONSTRUCTION!
            </IonCardTitle>
            <IonCardSubtitle class="landing-text-subtitle">
              Welcome to our open kickstarter test! Make friends, play games,
              and collect items.
            </IonCardSubtitle>
            <IonButton
              fill="clear"
              href="https://mastodon.online/@vgx"
              target="_blank">
              <IonIcon size="large" icon={logoMastodon} />
            </IonButton>
            <IonButton
              fill="clear"
              href="https://www.youtube.com/channel/UCrI4grL6iT4QZGchdPBK29A"
              target="_blank">
              <IonIcon size="large" icon={logoYoutube} />
            </IonButton>
            <IonButton
              fill="clear"
              href="https://discord.gg/axSRnuyQnJ"
              target="_blank">
              <IonIcon size="large" icon={logoDiscord} />
            </IonButton>
          </IonCardContent>
        </IonCard>
      </div>

      <div className="playnow-lottie">
        <LottiePlayer
          //TODO add onclick()
          onClick={() => dispatch(openSignin())}
          lottieJson={PlayNowBtn}
          lottieWidth="350px"
          lottieHeight="400px"></LottiePlayer>
      </div>
    </div>
  );
};

export default HomeLanding;
