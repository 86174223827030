// config.ts



interface AppConfig {
  clientUri: string;
  twitchParentUri: string;
  wardenUri: string;
}
console.log(process.env.REACT_APP_ENV);
let config: AppConfig;
const environment = process.env.REACT_APP_ENV;
if (environment === 'prod') {
  config = {
    clientUri: 'https://client.vgx.dev',
    twitchParentUri: 'www.vgx.dev',
    wardenUri: 'https://warden.vgx.dev',
  };
} else if (environment === 'stg') {
  config = {
    clientUri: 'https://client-stg.vgx.dev',
    twitchParentUri: 'www.stg.vgx.dev',
    wardenUri: 'https://warden-stg.vgx.dev',
  };
} else if (environment === 'dev') {
  config = {
    clientUri: 'https://client-dev.vgx.dev',
    twitchParentUri: 'www.dev.vgx.dev',
    wardenUri: 'https://warden-dev.vgx.dev',
  };
} else{
    config = {
    clientUri: 'https://client-dev.vgx.dev',
    twitchParentUri: 'localhost',
    wardenUri: 'https://warden-dev.loopystudios.com',
  };
}

export const appConfig = config;