import {configureStore} from '@reduxjs/toolkit'
// import signupReducer from './signup'
import signinReducer from './SignIn'
// import settingsReducer from './settings'
// import sidemenuReducer from './sidemenu';

export const store = configureStore({
    reducer: {
        // signup: signupReducer,
        signin: signinReducer,
        // settings: settingsReducer,
        // sideMenu: sidemenuReducer
    }
});

export type RootState = ReturnType<typeof store.getState>