import "./MainToolbar.css";

import React, { useState, useEffect } from "react";
import { isMobileDevice } from "../../helpers/mobile.service";
import FloatingToolbar from "../../layouts/Toolbar/FloatingToolbar";
import MobileToolbar from "../../layouts/Toolbar/MobileToolbar";
// import { ThemeContext } from "../../components/context/Theme/ThemeContext";
// TODO fix this
const MainToolbar: React.FC = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // const [isToolbarExpanded, setIsToolbarExpanded] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobileWeb = windowWidth < 1200 || isMobileDevice();

  // const toggleMenu = () => {
  //   setIsToolbarExpanded(!isToolbarExpanded);
  // };

  //   const routingChildren = useNavigationService(); // Use the useNavigationService function to get the segmentChildren list
  return (
    <div className="main-toolbar">
      {!isMobileWeb && <FloatingToolbar />}
      {isMobileWeb && <MobileToolbar />}
    </div>
  );
};

export default MainToolbar;
