// src/pages/Store.tsx

import React from "react";
import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonTitle,
} from "@ionic/react";
import StoreItemCard from "../components/StoreItemCard";
import Footer from "../layouts/Footer/Footer";

const Store: React.FC = () => {
  // TODO get from server
  const subscriptionItems = [
    {
      name: "Subscribe",
      type: "subscription",
      price: 9.99,
      description: "Monthly Plan",
      term: "1 month",
      img: "https://ionicframework.com/docs/img/demos/card-media.png",
    },
    {
      name: "Subscribe",
      type: "subscription",
      price: 19.99,
      description: "6 Month Plan",
      term: "6 month",
      img: "https://ionicframework.com/docs/img/demos/card-media.png",
    },
    {
      name: "Subscribe",
      type: "subscription",
      price: 89.99,
      description: "yearly Plan",
      term: "12 month",
      img: "https://ionicframework.com/docs/img/demos/card-media.png",
    },
    // Add more subscription items as needed
  ];

  const coinOptions = [
    {
      name: "Coins",
      type: "coin",
      price: 4.99,
      description: "50 Coins",
      amount: 50,
      img: "https://ionicframework.com/docs/img/demos/card-media.png",
    },
    {
      name: "Coins",
      type: "coin",
      price: 4.99,
      description: "50 Coins",
      amount: 50,
      img: "https://ionicframework.com/docs/img/demos/card-media.png",
    },
    {
      name: "Coins",
      type: "coin",
      price: 117.99,
      description: "1100 Coins",
      amount: 1100,
      img: "https://ionicframework.com/docs/img/demos/card-media.png",
    },
    // Add more coin options as needed
  ];
  // const modal = useRef<HTMLIonModalElement>(null);
  // const [loading, setLoading] = useState(false);
  // const [clientSecret, setClientSecret] = useState({});

  // const handleCheckout = async () => {
  //   setLoading(true);

  //   // // Create PaymentIntent when opening the modal

  //   // const response = await fetch(
  //   //   "https://ug5fny7vx3.execute-api.us-east-1.amazonaws.com/Prod/checkout",
  //   //   {
  //   //     // mode: "no-cors",
  //   //     method: "POST",
  //   //     headers: { "Content-Type": "application/json" },
  //   //     body: JSON.stringify({ amount: 100.0, currency: "usd" }),
  //   //   }
  //   // );

  //   // const data = await response.json();
  //   // console.log(data.paymentIntent);
  //   // setClientSecret(data.paymentIntent);

  //   setLoading(false);
  //   modal.current?.present();
  // };
  // const options = {
  //   mode: "payment",
  //   currency: "usd",
  //   amount: 1099,
  // };
  return (
    <IonPage>
      <IonContent fullscreen>
        {/* TODO clean this up */}
        <br />
        <br />
        <br />
        <br />
        <br />
        <IonTitle>Subcriptions</IonTitle>
        {/* TODO this is wrong */}
        <IonGrid>
          {/* Subscription Cards */}
          <IonRow>
            {subscriptionItems.map((item, index) => (
              <IonCol key={index} size-lg="4" size="12">
                <StoreItemCard {...item} />
              </IonCol>
            ))}
          </IonRow>
          <IonTitle>Coins</IonTitle>
          {/* TODO this is wrong */}
          {/* Coin Cards */}
          <IonRow>
            {coinOptions.map((item, index) => (
              <IonCol key={index} size-lg="4" size="12">
                <StoreItemCard {...item} />
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>

        {/* <IonButton id="checkout-button" expand="block" onClick={handleCheckout}>
          Checkout
        </IonButton> */}
        {/* <IonModal ref={modal} id="payment-modal">
          {loading ? (
            <IonLoading isOpen={true} />
          ) : (
            <Elements
              stripe={stripePromise}
              options={{
                mode: "payment",
                currency: "usd",
                amount: 1099,
              }}>
              <PaymentForm clientSecret={clientSecret} />
            </Elements>
          )}
        </IonModal> */}
        <Footer></Footer>
      </IonContent>
    </IonPage>
  );
};

export default Store;
