// navigation_service.js

import { useHistory } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { openSettings } from "../redux/settings";
import { home, logoDiscord, newspaper,  helpCircle } from "ionicons/icons";

function openCommunityDiscord() {
  window.open("https://discord.gg/axSRnuyQnJ");
}

export function useNavigationService() {
  const history = useHistory();
//   const dispatch = useDispatch();




  const handleRouteChange = (path) => {
    history.push(path);
  };

  const routingChildren = [
    { value: "Home", icon: home, onclick: () => handleRouteChange("/home")},
    { value: "About", icon: helpCircle, onclick: () => handleRouteChange("/about")},
    { value: "Community", icon: logoDiscord, onclick: () => openCommunityDiscord()},
    { value: "Blog", icon: newspaper, onclick: () => handleRouteChange("/blog") },
  ];

  return routingChildren; // Return the segmentChildren list
}
// TODO: integrate with nav bar so we dont have two lists