import React, { useState } from "react";
import {
  IonButton,
  IonIcon,
  IonImg,
  IonToolbar,
  IonCard,
  IonList,
  IonLabel,
  IonItem,
  IonPopover,
} from "@ionic/react";
import "./MobileToolbar.css";
import logo from "../../assets/logos/vgx-light.svg";
import { closeOutline, menuOutline, person } from "ionicons/icons";
import { useNavigationService } from "../../helpers/navigation.service";
import AccountPopover from "../../features/AccountPopover/AccountPopover";
import { useDispatch } from "react-redux";
import { openSignin } from "../../helpers/redux/SignIn";
const MobileToolbar: React.FC = () => {
  const [isToolbarExpanded, setIsToolbarExpanded] = useState(false);
  const toggleMenu = () => {
    setIsToolbarExpanded(!isToolbarExpanded);
  };
  const routingChildren = useNavigationService();
  const [event, setEvent] = useState<any>(null);
  const [showPopover, setShowPopover] = useState(false);
  const handlePopover = (e: any) => {
    setShowPopover(true);
    setEvent(e);
  };
  const dispatch = useDispatch();
  return (
    <>
      <IonToolbar class="mobile-toolbar ">
        <IonButton
          slot="start"
          fill="clear"
          color={"dark"}
          size="small"
          onClick={() => setShowPopover(true)}>
          <IonIcon slot="icon-only" icon={person}></IonIcon>
        </IonButton>
        <IonPopover
          size="auto"
          isOpen={showPopover}
          mode="ios"
          onDidDismiss={() => setShowPopover(false)}>
          <AccountPopover onClose={() => handlePopover} />
        </IonPopover>
        <IonImg
          src={logo}
          style={{ height: "40px" }}
          class="mobile-toolbar-img"></IonImg>

        <IonButton onClick={toggleMenu} fill="clear" slot="end" color={"dark"}>
          <IonIcon
            icon={isToolbarExpanded ? closeOutline : menuOutline}
            className={isToolbarExpanded ? "close-icon" : "menu-icon"}
          />
        </IonButton>
      </IonToolbar>
      {isToolbarExpanded && (
        <div className="hidden-content">
          <IonCard className="toolbar-card">
            <IonList>
              {routingChildren.map((child, index) => (
                <div key={index}>
                  <IonItem
                    button
                    onClick={(event) => {
                      toggleMenu();
                      child.onclick();
                    }}>
                    <IonIcon
                      slot="start"
                      className="community-icon"
                      icon={child.icon}
                    />
                    <IonLabel className="label">{child.value}</IonLabel>
                  </IonItem>
                </div>
              ))}
            </IonList>
            <div className="ion-padding">
              <IonButton
                color={"secondary"}
                expand="block"
                onClick={() => dispatch(openSignin())}>
                Play Now
              </IonButton>
            </div>
            <br />
          </IonCard>
        </div>
      )}
    </>
  );
};

export default MobileToolbar;
