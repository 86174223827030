import React, { createContext, useState, ReactNode } from "react";
import preferTheme from "../../helpers/theme.service";

//TODO fix this
interface ThemeContextProps {
  isLightMode: boolean;
  toggleTheme: () => void;
}

const ThemeContext = createContext<ThemeContextProps>({
  isLightMode: true, // Default theme
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleTheme: () => {},
});

interface ThemeProviderProps {
  children: ReactNode;
}

const storedTheme = localStorage.getItem("themePreference");
// If a stored theme preference is found, use it; otherwise, use system preference

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  // Get the browser's preferred theme as a default
  const defaultTheme =
    storedTheme === "dark" ||
    (!storedTheme && window.matchMedia("(prefers-color-scheme: dark)").matches);
  // Use state to track the current theme (true for light, false for dark)
  const [isLightMode, setIsLightMode] = useState(defaultTheme);

  // Toggle function to switch between light and dark mode
  const toggleTheme = () => {
    setIsLightMode((prevMode) => !prevMode);
    preferTheme.setTheme();
  };

  // Context value to be passed to consumers
  const contextValue = {
    isLightMode,
    toggleTheme,
  };

  // Provide the context value to the children components
  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeProvider, ThemeContext };
