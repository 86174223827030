/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import {
  IonButtons,
  IonButton,
  IonModal,
  IonContent,
  IonToolbar,
  IonTitle,
  IonInput,
  IonCheckbox,
  IonHeader,
} from "@ionic/react";
import "./SignInModal.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../helpers/redux/Store";
import { closeSignin } from "../../helpers/redux/SignIn";
import { IonRow, IonCol } from "@ionic/react";
import { useHistory } from "react-router-dom";

const SignInModal: React.FC = () => {
  const history = useHistory();
  const signinModalVisible = useSelector(
    (state: RootState) => state.signin.visible
  );
  const dispatch = useDispatch();
  const [passwordVal, setSubmitPasswordVal] = useState("");
  const [uidVal, setSubmitUidVal] = useState("");
  // const [rememberMe, setRememberMe] = useState(false);

  async function dismiss() {
    dispatch(closeSignin());
  }

  return (
    <IonModal isOpen={signinModalVisible} onWillDismiss={() => dismiss()}>
      <IonHeader>
        <IonToolbar>
          <IonTitle color="dark">Login</IonTitle>
          <IonButtons slot="end">
            <IonButton color="dark" onClick={() => dispatch(closeSignin())}>
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {/* If user is authenticated, show the "You are already signed in" modal */}
        {/* {authService.checkAuthentication() ? (
          <AlreadySignedIn></AlreadySignedIn>
        ) : ( */}
        <>
          {/* <LottiePlayer
              lottieJson={ConstructLottie}
              lottieHeight="250px"
              lottieWidth="120%"
              lottieSpeed={1}
            /> */}
          <form>
            <div className="input-group">
              <IonInput
                required={true}
                mode="md"
                fill="outline"
                label="Email"
                labelPlacement="floating"
                placeholder="myemail@email.com"
                onIonInput={(ev: any) => setSubmitUidVal(ev.target.value)}
                value={uidVal.toLowerCase()}></IonInput>
            </div>
            <br />
            <div className="input-group">
              <IonInput
                required={true}
                placeholder="Enter your Password"
                type="password"
                label="Password"
                mode="md"
                fill="outline"
                labelPlacement="floating"
                onIonInput={(ev: any) => setSubmitPasswordVal(ev.target.value)}
                value={passwordVal}></IonInput>
            </div>
            <IonRow>
              <IonCol>
                <div className="input-group">
                  <IonCheckbox
                    mode="md"
                    class="remember-checkbox"
                    title="Remember Me"
                    name="Remember Me"
                    labelPlacement="end">
                    Remember me
                  </IonCheckbox>
                </div>
              </IonCol>
              <IonCol sizeLg="3" sizeMd="3" size="4">
                <div>
                  <IonButton
                    mode="md"
                    shape="round"
                    fill="outline"
                    slot="end"
                    disabled
                    //   onClick={() => {
                    //     onLoginHandler({
                    //       variables: { uidVal, passwordVal },
                    //     });
                    //   }}
                  >
                    Submit
                  </IonButton>
                </div>
              </IonCol>
            </IonRow>
          </form>

          <IonButton
            fill="clear"
            onClick={() => {
              history.push("/play");
              dismiss();
            }}>
            Continue as Guest
          </IonButton>
        </>
        {/* )} */}
      </IonContent>
    </IonModal>
  );
};

export default SignInModal;
