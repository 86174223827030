// TODO remove if unused
class PreferTheme {
  constructor() {
   this.loadTheme(); // Load theme preference on initialization
    this.setThemeAttribute(this.prefersDark ? 'dark' : 'light');
    this.themeCallback = false; // Initialize the callback
    this.tableThemeCallback = false; // Initialize the table theme callback
  }
  loadTheme() {
    const storedTheme = localStorage.getItem("themePreference");
    // If a stored theme preference is found, use it; otherwise, use system preference
    this.prefersDark =
      storedTheme === "dark" ||
      (!storedTheme && window.matchMedia("(prefers-color-scheme: dark)").matches);
    this.setThemeAttribute(this.prefersDark ? 'dark' : 'light');
  }
  getTheme() {
    return this.prefersDark;
  }

  getTableThemes() {
    if (this.prefersDark) {
      return {
        table1: 'prctbl_1NR3vTHMqt4WJaDG2dGNlXg2',
        table2: 'prctbl_1NRUxNHMqt4WJaDG9M90bASZ'
      };
    } else {
      return {
        table1: 'prctbl_1NRUlfHMqt4WJaDGH4uZWp3l',
        table2: 'prctbl_1NR2iNHMqt4WJaDG61qlnG2h'
      };
    }
  }

  setTheme() {
    const newTheme = this.prefersDark ? 'light' : 'dark';
    this.setThemeAttribute(newTheme);

    if (this.themeCallback) {
      this.themeCallback(this.prefersDark);
    }
    if (this.tableThemeCallback) {
      this.tableThemeCallback(this.getTableThemes());
    }
    localStorage.setItem("themePreference", newTheme);
  }

  setThemeAttribute(theme) {
    if (theme === 'dark') {
      document.documentElement.setAttribute('data-scheme', 'dark');
      this.prefersDark = true;
    } else {
      document.documentElement.removeAttribute('data-scheme');
      this.prefersDark = false;
    }
    
  }

  onThemeCallback(callback) {
    this.themeCallback = callback;
  }

  onTableThemeCallback(callback) {
    this.tableThemeCallback = callback;
  }
}

const preferTheme = new PreferTheme();
export default preferTheme;