import React from "react";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { ThemeProvider } from "./context/Theme/ThemeContext";

import {
  home,
  people,
  gameController,
  storefront,
  settings,
} from "ionicons/icons";
import { Route, Redirect } from "react-router";
import About from "./pages/About";
import Home from "./pages/Home";
import Play from "./pages/Play";
import Store from "./pages/Store";
import MainToolbar from "./components/MainToolbar/MainToolbar";
import SignInModal from "./features/SignInModal/SignInModal";
import { Provider } from "react-redux";
import { store } from "./helpers/redux/Store";

setupIonicReact();

// const stripePromise = loadStripe(
//   "pk_test_51OZhZLDdpyDyRA4sKcq9v5VIDzCi8V3w5CqTot7EQp10SXdiabFYRDT6uJEnpEbJxHJdfJR4zOQPqHT2CKCRdlwI00J5NECq0gyour-publishable-key"
// );

const App: React.FC = () => (
  // <Elements stripe={stripePromise}>
  <Provider store={store}>
    <IonApp>
      <IonReactRouter>
        {/* In our case tabBar is our router as well as the tabs */}
        <ThemeProvider>
          <IonTabs>
            {/* Tabs must have router */}
            <IonRouterOutlet>
              <Route exact path="/home">
                <Home />
              </Route>
              <Route exact path="/play">
                <Play />
              </Route>
              <Route path="/store">
                <Store />
              </Route>
              <Route path="/about">
                <About />
              </Route>
              <Route exact path="/">
                <Redirect to="/home" />
              </Route>
            </IonRouterOutlet>
            <IonTabBar slot="bottom">
              <IonTabButton tab="home" href="/home">
                <IonIcon aria-hidden="true" icon={home} />
                <IonLabel>Home</IonLabel>
              </IonTabButton>
              <IonTabButton tab="social" disabled>
                <IonIcon aria-hidden="true" icon={people} />
                <IonLabel>Social</IonLabel>
              </IonTabButton>
              <IonTabButton tab="play" href="/play">
                <IonIcon aria-hidden="true" icon={gameController} />
                <IonLabel>Play</IonLabel>
              </IonTabButton>
              <IonTabButton tab="store" href="/store">
                <IonIcon aria-hidden="true" icon={storefront} />
                <IonLabel>Store</IonLabel>
              </IonTabButton>
              <IonTabButton tab="account" disabled>
                <IonIcon aria-hidden="true" icon={settings} />
                <IonLabel>Account</IonLabel>
              </IonTabButton>
              {/* <IonTabButton tab="admin" disabled>
          <IonIcon aria-hidden="true" icon={logoIonitron} />
          <IonLabel>Admin</IonLabel>
        </IonTabButton> */}
            </IonTabBar>
          </IonTabs>
          <SignInModal></SignInModal>
          <MainToolbar></MainToolbar>
        </ThemeProvider>
      </IonReactRouter>
    </IonApp>
  </Provider>
  // </Elements>
);

export default App;
