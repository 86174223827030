import React from "react";
import {
  IonButton,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonItem,
  IonRow,
  IonSkeletonText,
} from "@ionic/react";
import { IonCard } from "@ionic/react";
const cardData = [
  {
    title: "Card 1 Title",
    subtitle: "Card 1 Subtitle",
    content: "Card 1 content goes here.",
    imageUrl: "https://ionicframework.com/docs/img/demos/card-media.png",
  },
  {
    title: "Card 2 Title",
    subtitle: "Card 2 Subtitle",
    content: "Card 2 content goes here.",
    imageUrl: "https://ionicframework.com/docs/img/demos/card-media.png",
  },
  {
    title: "Card 3 Title",
    subtitle: "Card 3 Subtitle",
    content: "Card 3 content goes here.",
    imageUrl: "https://ionicframework.com/docs/img/demos/card-media.png",
  },
];

const HomeNews: React.FC = () => {
  return (
    <div>
      {/* TODO make this dynamic data, make this font size */}
      <h2 className="ion-text-center">News</h2>
      <div>
        <IonGrid>
          <IonRow>
            {cardData.map((card, index) => (
              <IonCol key={index} size-lg="4" size="12">
                <IonCard>
                  {/* <img alt={`Image ${index + 1}`} src={card.imageUrl} /> */}
                  <IonItem>
                    {/* <IonThumbnail slot="start"> */}
                    <IonSkeletonText
                      animated={true}
                      style={{ height: "340px" }}></IonSkeletonText>
                    {/* </IonThumbnail> */}
                  </IonItem>

                  <IonCardHeader>
                    <IonCardTitle>
                      {/* {card.title} */}
                      <IonSkeletonText
                        animated={true}
                        style={{ width: "140px" }}></IonSkeletonText>
                    </IonCardTitle>
                    <IonCardSubtitle>
                      {/* {card.subtitle} */}
                      <IonSkeletonText
                        animated={true}
                        style={{ width: "240px" }}></IonSkeletonText>
                    </IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent>
                    {/* {card.content} */}
                    <IonSkeletonText
                      animated={true}
                      style={{
                        width: "340px",
                      }}></IonSkeletonText>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            ))}
          </IonRow>
          <IonRow>
            <IonCol className="ion-text-center">
              <IonButton mode="md" size="large" disabled>
                View All
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

export default HomeNews;
