import React from "react";
import { ThemeContext } from "../../context/Theme/ThemeContext";
import "./ThemeSwitch.css";
import { useContext } from "react";

const ThemeSwitch: React.FC = () => {
  const { isLightMode, toggleTheme } = useContext(ThemeContext);

  return (
    <div>
      <button
        className="theme-switch-container"
        id="darkmode-toggle"
        onClick={toggleTheme}
        //checked={isDarkMode}
      >
        <div className={`sun ${isLightMode ? "hidden" : "visible"}`}></div>
        <div className={`moon ${isLightMode ? "visible" : "hidden"}`}>
          <div className="star"></div>
          <div className="star small"></div>
        </div>
      </button>
    </div>
  );
};

export default ThemeSwitch;
