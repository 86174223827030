// src/components/CheckoutModal.tsx
import React, { useEffect, useState } from "react";
import {
  IonModal,
  IonTitle,
  IonButton,
  IonLoading,
  IonContent,
} from "@ionic/react";
import { StripePaymentElementOptions, loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "../../components/PaymentForm";
import LottiePlayer from "../../components/LottiePlayer";
import ShoppingLottie from "../../assets/lotties/sheep-shoping.json";

interface CheckoutModalProps {
  isOpen: boolean;
  onClose: () => void;
  item: {
    type: string;
    price: number;
    description: string;
    term?: string;
    amount?: number;
    img: string;
    name: string;
  };
}
const stripePromise = loadStripe(
  "pk_test_51OZhZLDdpyDyRA4sKcq9v5VIDzCi8V3w5CqTot7EQp10SXdiabFYRDT6uJEnpEbJxHJdfJR4zOQPqHT2CKCRdlwI00J5NECq0g"
); // Replace with your actual key

const CheckoutModal: React.FC<CheckoutModalProps> = ({
  isOpen,
  onClose,
  item,
}) => {
  const [loading, setLoading] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [clientSecret, setClientSecret] = useState({});

  useEffect(() => {
    // Set a timeout to simulate a loading delay of 1 second
    const timeoutId = setTimeout(() => {
      setLoading(false);
      //   setShowContent(true);
    }, 3500);

    // Cleanup the timeout when the component unmounts or when the modal is closed
    return () => clearTimeout(timeoutId);
  }); // The empty dependency array ensures that this effect runs only once when the component mounts
  const handleDismiss = () => {
    // Reset states when the modal is dismissed
    // setLoading(true);
    setShowContent(false);
    setClientSecret({});
    onClose();
  };
  const handleWillPresent = () => {
    // Reset states when the modal is dismissed
    setShowContent(true);
    setLoading(true);

    // setClientSecret({});
    // onClose();
  };
  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={handleDismiss}
      keepContentsMounted={true}
      onWillPresent={handleWillPresent}>
      {loading ? (
        <LottiePlayer
          lottieJson={ShoppingLottie}
          lottieWidth={"600px"}
          lottieHeight={"500px"}
        />
      ) : (
        <>
          {showContent && (
            <IonContent class="ion-padding">
              <img alt={item.name} src={item.img} />
              <p>
                {item.type === "subscription"
                  ? `Term: ${item.term}`
                  : `Amount: ${item.amount}`}
              </p>
              <p>Price: ${item.price.toFixed(2)}</p>
              <Elements
                stripe={stripePromise}
                options={{
                  mode: "payment",
                  currency: "usd",
                  amount: 1099,
                }}>
                <PaymentForm clientSecret={clientSecret} />
              </Elements>
              <IonButton fill="outline" expand="block" onClick={onClose}>
                Cancel
              </IonButton>
            </IonContent>
          )}
        </>
      )}
    </IonModal>
  );
};

export default CheckoutModal;
