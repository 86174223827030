import React from "react";
import {
  IonContent,
  IonButton,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
} from "@ionic/react";
import { cog, bug } from "ionicons/icons";
import ThemeSwitch from "../../components/ThemeSwitch/ThemeSwitch"; // Import ThemeSwitch component if it exists
import "./AccountPopover.css";
import { useDispatch } from "react-redux";
import { openSignin } from "../../helpers/redux/SignIn";

interface AccountPopoverProps {
  onClose: () => void;
}

const AccountPopover: React.FC<AccountPopoverProps> = ({ onClose }) => {
  const handleItemClick = () => {
    // Do something when an item is clicked
    onClose(); // Close the popover
  };
  const dispatch = useDispatch();

  return (
    <IonContent class="ion-padding">
      <IonButton
        expand="block"
        color="secondary"
        // TODO this should probably be a different modal or a new prop is needed
        onClick={() => dispatch(openSignin())}>
        Log In
      </IonButton>
      <IonList inset mode="ios">
        <IonItem
          button={true}
          detail={false}
          lines="full"
          onClick={handleItemClick}>
          <IonIcon slot="start" icon={cog} size="large"></IonIcon>
          <IonLabel>Settings</IonLabel>
        </IonItem>
        <IonItem
          button={true}
          detail={false}
          lines="full"
          onClick={handleItemClick}>
          <IonIcon slot="start" icon={bug} size="large"></IonIcon>
          <IonLabel>Report Bug</IonLabel>
        </IonItem>
        <IonItem className="popover-secondary-list-item">
          <IonLabel>Theme</IonLabel>
          <ThemeSwitch />
        </IonItem>
      </IonList>
    </IonContent>
  );
};

export default AccountPopover;
