import React, { useRef } from "react";
import { IonContent, IonHeader, IonPage } from "@ionic/react";
import "./Play.css";
import { appConfig } from "../config";

const Play: React.FC = () => {
  const iframeRef = useRef(null);
  const iframeUrl = appConfig.clientUri; // Replace with the URL of the iframe content
  return (
    <IonPage>
      <IonHeader></IonHeader>
      <IonContent fullscreen={false}>
        <div className="client-containter">
          <iframe
            title="client"
            ref={iframeRef}
            src={iframeUrl}
            allowFullScreen
            className="game-client"></iframe>
        </div>
        {/* <IonButton onClick={enterFullscreen}>Enter Fullscreen</IonButton> */}
      </IonContent>
    </IonPage>
  );
};

export default Play;
