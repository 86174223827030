import React from "react";
import { IonContent, IonPage } from "@ionic/react";
import "./About.css";
import Footer from "../layouts/Footer/Footer";

const About: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="about-container">
          <div>
            <h1 className="about-header">About</h1>
            <p>
              We are Vector Game Experts (VGX), a studio of industry
              professionals and developers woarking on creating the social
              paradise for people of all ages.
            </p>
            <h2 className="about-header">Who are we?</h2>
            <p>
              We were founded as a team of seven with a diverse range of talent.
              Our software engineers currently support NASA, Blue Origin, Tesla,
              and Ford. We are academics at heart, and see ourselves solving
              another hard problem with a great deal of passion. We have built
              game engines and launched small games on Steam, and to that end -
              we have planned and believe in our capability to meet high
              expectations.
            </p>
            <h2 className="about-header">What are we building?</h2>
            <p>
              We are building a modern multiplayer social game, designed for all
              ages. It will be playable on native platforms or on the web, with
              no download required. Quickly invite your guest to join through a
              generated hyperlink! Our custom engine will support lossless
              graphic fidelity independent of scale, and unique effects through
              path geometry manipulation, never seen before. We are drawing
              inspiration from Club Penguin and RuneScape.
            </p>
            <h2 className="about-header">How are we building it?</h2>
            <p>
              We are building a scalable service, ready to accept bursty or
              consistent traffic on a variety of cloud servers. This game will
              feature the first vector graphic engine empowered through Lottie
              and SVG, and be the first interactive game using this rendering
              capability. Players on native or web clients can play together
              effortlessly through abstractions we have made over WebRTC.
            </p>
            <h2 className="about-header">How far are we?</h2>
            <p>
              We have built our vector engine, and have players talking and
              moving over the network within our shared world. We are far away
              from where we need to be, but expect to deliver an open or closed
              demo in 2024.
            </p>
          </div>
        </div>
        <Footer></Footer>
      </IonContent>
    </IonPage>
  );
};

export default About;
